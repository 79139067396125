import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorService } from 'src/app/shared/error-dialog/error.service';
import {
  descriptionMaxLength,
  getDateFormat,
} from 'src/app/shared/helpers/various-helpers.helper';
import { AccountDetails } from 'src/app/dashboard/models/account-details.model';
import { FavouriteTransfer } from '../shared/models/favourite-transfer.model';
import { transferAmountValidator } from '../shared/transfer-amount.validator';
import { TransferService } from '../transfer.service';
import { e2ePhoneNumberValidator } from './e2e-phone-number.validator';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { phoneValidator } from 'src/app/shared/phone.validator';
import { TransferType } from '../shared/enum/transfer-type.enum';
import { E2eBeneficiaryDetails } from '../shared/models/e2e-beneficiary-details.model';
import { Transfer, TransferWithOtp } from '../shared/models/transfer.model';
import { GetPriceList } from '../shared/models/get-price-list.model';
import { PriceList } from '../shared/models/price-list.model';

@Component({
  templateUrl: './e2e-now.component.html',
  styleUrls: ['./e2e-now.component.scss'],
})
export class E2eNowComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isTransferring: boolean = false;
  isInvalidOtp: boolean = false;
  isConfirmationStep: boolean = false;
  transactionId?: number;
  favourite?: FavouriteTransfer;
  accountOwnerGuid!: string;
  transferBody?: Transfer | null;
  otpVerifyToken!: string; // otp token to verify transaction in core banking (not the sms)

  transferForm: FormGroup = this.fb.group(
    {
      accounts: this.fb.group({
        fromAccount: [null, Validators.required],
        toUser: this.fb.group(
          {
            phoneNumber: [
              null,
              [Validators.required, phoneValidator()],
              e2ePhoneNumberValidator(
                this.getIbanAccountByPhoneNumber.bind(this)
              ),
            ],
            name: [null, Validators.required],
            beneficiaryUserId: [null, Validators.required],
            beneficiaryIban: [null, Validators.required],
          },
          {
            updateOn: 'blur',
          }
        ),
      }),
      otherFields: this.fb.group({
        amount: [
          null,
          [Validators.required, Validators.pattern('^[0-9]+[.]?[0-9]{0,2}$')],
        ],
        fee: [null, Validators.required],
        executionDate: [null, Validators.required],
        payerDescription: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9 ]*$'),
            Validators.maxLength(descriptionMaxLength),
          ],
        ],
        beneficiaryDescription: [
          null,
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9 ]*$'),
            Validators.maxLength(descriptionMaxLength),
          ],
        ],
      }),
    },
    {
      validators: [transferAmountValidator()],
    }
  );

  private beneficiarySub?: Subscription;
  private amountSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private errorService: ErrorService,
    private transferService: TransferService,
    private dashboardService: DashboardService
  ) {
    this.favourite =
      this.router.getCurrentNavigation()?.extras.state?.favourite;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.dashboardService.getAccounts(TransferType.E2E).subscribe(
      (accounts) => {
        this.accountOwnerGuid = accounts.accountOwnerGuid;
        this.setDefaultValues(accounts.iban, this.favourite);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );

    this.beneficiarySub = this.beneficiaryIbanControl.valueChanges.subscribe(
      async () => {
        await this.getPriceList();
      }
    );

    // sets fee value to feeControl
    this.amountSub = this.amountControl.valueChanges.subscribe(
      async (amount) => {
        if (amount !== null) {
          this.feeControl.markAsPending();
          await this.getPriceList();
        }
      }
    );
  }

  async getPriceList() {
    if (
      this.beneficiaryIbanControl?.value !== null &&
      this.amountControl?.value !== null
    ) {
      const model: GetPriceList = {
        accountOwnerGuid: this.accountOwnerGuid,
        accountCode: this.fromAccountControl?.value?.code,
        paymentType: TransferType.E2E,
        currency: 'EUR',
        amount: this.amountControl?.value,
        chargeType: 'our',
        benIbanOrAccountNumber: this.beneficiaryIbanControl?.value,
      };
      (await this.transferService.getPriceList(model)).subscribe(
        (priceList: PriceList[]) => {
          this.feeControl.setValue(priceList[0]);
        },
        () => this.errorService.showErrorDialog()
      );
    }
  }

  ngOnDestroy(): void {
    this.amountSub?.unsubscribe();
    this.beneficiarySub?.unsubscribe();
  }

  async goToConfirmationStep(): Promise<void> {
    const transfer = this.prepareTransferBody();
    (await this.transferService.createTransfer(transfer)).subscribe(
      async (data: { otpVerifyToken: string }) => {
        this.otpVerifyToken = data.otpVerifyToken;
        (await this.transferService.sendE2enowOtp(transfer)).subscribe(
          () => {
            this.isConfirmationStep = true;
          },
          () => {
            this.errorService.showErrorDialog();
          }
        );
      },
      (error) => {
        error.status === 400
          ? this.errorService.showErrorDialog(error.error.message)
          : this.errorService.showErrorDialog();
      }
    );
  }

  private prepareTransferBody(): Transfer {
    const transfer: Transfer = {
      transferType: TransferType.E2E,
      accountOwnerGuid: this.accountOwnerGuid,
      fromAccountId: this.fromAccountControl.value.id,
      fromAccountGuid: this.fromAccountControl.value.guid,
      e2eBeneficiaryUserId: this.beneficiaryUserIdControl.value,
      executionDate: this.executionDateControl?.value,
      amount: this.amountControl?.value,
      toAmount: this.amountControl?.value,
      benRateCurrency: 'EUR',
      benRateValue: '1',
      payerDescription: this.payerDescriptionControl?.value,
      beneficiaryDescription: this.beneficiaryDescriptionControl?.value,
      chargesId: this.feeControl?.value?.id,
      chargesAmount: this.feeControl?.value?.calculatedAmount,
    };
    return transfer;
  }

  cancelTransfer(shouldReload: boolean): void {
    this.transferBody = null;
    this.isInvalidOtp = false;
    this.transactionId = undefined;
    this.isConfirmationStep = false;
    this.toUserGroup.reset(); // reset beneficiary info
    if (shouldReload) {
      this.reloadAccount();
    } else {
      this.setDefaultValues(this.fromAccountControl.value);
    }
  }

  async onFavouriteSelect(favourite: FavouriteTransfer): Promise<void> {
    this.favourite = favourite;
    this.setDefaultValues(this.fromAccountControl.value, this.favourite);
  }

  async transfer(otpCode: string): Promise<void> {
    this.isInvalidOtp = false; // reset value
    this.isTransferring = true;
    this.transferBody = this.prepareTransferBody();
    const body: TransferWithOtp = {
      ...this.transferBody,
      otp: otpCode,
      otpVerifyToken: this.otpVerifyToken,
    };
    (await this.transferService.transferE2enow(body)).subscribe(
      () => {
        this.transactionId = 1;
        this.isTransferring = false;
      },
      (err) => {
        this.isTransferring = false;
        if (err.status === 400) {
          this.isInvalidOtp = true;
        } else {
          this.errorService.showErrorDialog();
        }
      }
    );
  }

  private reloadAccount(): void {
    this.isLoading = true;
    this.dashboardService.getAccounts(TransferType.E2E).subscribe(
      (accounts) => {
        this.setDefaultValues(accounts.iban);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.errorService.showErrorDialog();
      }
    );
  }

  private async setDefaultValues(
    fromAccount: AccountDetails,
    favourite?: FavouriteTransfer
  ): Promise<void> {
    await Promise.all([
      favourite
        ? this.transferService.getPhoneNumberByIbanAccount(
            this.accountOwnerGuid,
            favourite.benId
          )
        : null,
    ])
      .then(([phoneNumberData]) => {
        const newFormValue = {
          accounts: {
            fromAccount,
          },
          otherFields: {
            amount: 0,
            executionDate: getDateFormat(new Date()),
            payerDescription: '',
            beneficiaryDescription: '',
          },
        };

        this.transferForm.patchValue(newFormValue);
        setTimeout(() =>
          this.phoneNumberControl.setValue(phoneNumberData?.phoneNumber)
        ); // timeout used to avoid calling async validator multiple times
        this.transferForm.markAsUntouched();
      })
      .catch(() => {
        this.errorService.showErrorDialog();
      });
  }

  private getIbanAccountByPhoneNumber(phoneNumber: string): Promise<void> {
    return this.transferService
      .getIbanAccountByPhoneNumber(phoneNumber)
      .then((details: E2eBeneficiaryDetails) => {
        this.nameControl.setValue(details.name);
        this.beneficiaryUserIdControl.setValue(details.beneficiaryUserId);
        this.beneficiaryIbanControl.setValue(details.beneficiaryIban);
      })
      .catch((err) => {
        this.nameControl.reset();
        this.beneficiaryUserIdControl.reset();
        this.beneficiaryIbanControl.reset();
        throw new Error(err.status); // throw error so it can be caught by catch() in e2e-phone-number.validator.ts
      });
  }

  get accountsGroup(): FormGroup {
    return this.transferForm.get('accounts') as FormGroup;
  }
  get fromAccountControl(): FormControl {
    return this.accountsGroup.get('fromAccount') as FormControl;
  }
  get toUserGroup(): FormGroup {
    return this.accountsGroup.get('toUser') as FormGroup;
  }
  get nameControl(): FormControl {
    return this.toUserGroup.get('name') as FormControl;
  }
  get phoneNumberControl(): FormControl {
    return this.toUserGroup.get('phoneNumber') as FormControl;
  }
  get beneficiaryUserIdControl(): FormControl {
    return this.toUserGroup.get('beneficiaryUserId') as FormControl;
  }
  get beneficiaryIbanControl(): FormControl {
    return this.toUserGroup.get('beneficiaryIban') as FormControl;
  }
  get otherFieldsGroup(): FormGroup {
    return this.transferForm.get('otherFields') as FormGroup;
  }
  get amountControl(): FormControl {
    return this.otherFieldsGroup.get('amount') as FormControl;
  }
  get executionDateControl(): FormControl {
    return this.otherFieldsGroup.get('executionDate') as FormControl;
  }
  get feeControl(): FormControl {
    return this.otherFieldsGroup.get('fee') as FormControl;
  }
  get payerDescriptionControl(): FormControl {
    return this.otherFieldsGroup.get('payerDescription') as FormControl;
  }
  get beneficiaryDescriptionControl(): FormControl {
    return this.otherFieldsGroup.get('beneficiaryDescription') as FormControl;
  }
}
