<div class="buttons-container" fxLayoutAlign="start center" fxLayoutGap="20px">
  <!-- <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="unpaid-fees"
    routerLinkActive="active"
  >
    Unpaid monthly fees
  </button> -->
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="user-ip-login"
    routerLinkActive="active"
  >
    IP Login
  </button>
  <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="expiring-documents"
    routerLinkActive="active"
  >
    Expiring documents
  </button>
  <!-- <button
    mat-flat-button
    color="accent"
    class="g-small"
    routerLink="transactions-number"
    routerLinkActive="active"
  >
    Number of transactions
  </button> -->
</div>
<router-outlet></router-outlet>
