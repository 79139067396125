<div class="section-container">
  <div fxLayoutAlign="space-between" fxLayoutGap="10px">
    <span>{{ transaction?.createdAt | date: 'shortDate' }}</span>
  </div>
  <div fxLayoutAlign="space-between" fxLayoutGap="10px" class="bolded">
    <div class="text-overflow">
      <span>{{ transaction?.transactionCodeName }}</span>
    </div>
    <div
      [ngClass]="
        transaction?.typeName === TransactionTypeName.CREDIT
          ? 'credit amount'
          : 'debit text-overflow amount'
      "
    >
      <span>
        {{ transaction?.fromAmount | statementAmount: transaction?.typeName }}
        {{ transaction?.fromCCY }}
      </span>
    </div>
  </div>
  <div fxLayoutAlign="space-between" fxLayoutGap="10px">
    <div class="text-overflow">
      <span>Reference No. {{ transaction?.transactionRefId }}</span>
    </div>
    <div class="text-overflow">
      <span class="no-wrap"
        >Balance: {{ transaction?.currentBalance | amount }}
        {{ transaction?.currentBalanceCcy }}</span
      >
    </div>
  </div>
</div>
<mat-divider></mat-divider>
